import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';



const Hadear1 = () => {
    const [toggle, setToggle] = useState(false);

    const CloseClick = () => {
        setToggle(false);

    };
    const [ismobile, setIsmobile] = useState(0);

    // const { categorytotal } = useSelector((store) => store.categoryAll);


    const [showAbout, setShowAbout] = useState(true);
    const [showInterior, setShowInterior] = useState(true);
    const [showGallery, setShowGallery] = useState(true);

    const closemenu = () => {
        document.getElementById("mytogglebtn").classList.add("collapsed");
        document.getElementById("menu").classList.remove("in");
    };


    return (
        <>


            <header className="header-area stricky asda" style={{ backgroundColor: "" }}>
                <div className="container">
                    <div className="row">
                        {/* <h6 style={{textAlign:"center", fontSize:"10px", marginTop:"5px"}}><b>License No. 1593</b></h6> */}
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="outer-box clearfix">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="./assets/img/car/Auto_world_logo.png" alt="Awesome Logo" style={{ width: "200px", height: "75px" }} />
                                    </Link>
                                </div>
                                <nav className="main-menu">
                                    <div className="navbar-header">
                                        <button
                                            id="mytogglebtn"
                                            v
                                            className="navbar-toggle collapsed"
                                            data-toggle="collapse"
                                            data-target="#menu"
                                        >
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                        </button>
                                    </div>
                                    <div className="navbar-collapse collapse clearfix" id="menu">
                                        <ul className="navigation clearfix" style={{ fontFamily: "Arial, Helvetica, sans-serif" }} >
                                            <li className="current" >
                                                <Link to="/" onClick={closemenu}>
                                                    Home
                                                </Link>
                                            </li>

                                            <li className="">
                                                <Link to="/about" onClick={closemenu}>About Us</Link>
                                            </li>
                                            <li className="">
                                                <Link to="/Buy" onClick={closemenu}>Buy</Link>
                                            </li>
                                            <li className="">
                                                <a to="services.html" onClick={closemenu}>Sell</a>
                                            </li>
                                            <li className="">
                                                <Link to="/service" onClick={closemenu}>Service</Link>
                                            </li>
                                            <li className="">
                                                <Link to="/blogs" onClick={closemenu} style={{color:"white"}}>Blogs</Link>
                                            </li>
                                            <li className="">
                                                <Link to="/faq" onClick={closemenu} style={{color:"white"}}>FAQs</Link>
                                            </li>
                                            <li className="" style={{color:"white"}}>
                                                <Link to="/contact" style={{color:"white"}} onClick={closemenu}>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


        </>
    )
}

export default Hadear1