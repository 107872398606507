import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainLayOut from "./component/MainLayOut";
import "./App.css";
import { useEffect } from "react";
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import AboutUs from "./pages/about/AboutUs";
import FAQ from "./pages/home/FAQ";
import HomeFAQ from "./pages/faq/HomeFAQ";
import Service from "./pages/service/Service";
import Blogs from "./pages/blog/Blogs";
import Buy from "./pages/buy/Buy";



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/service" element={<Service />} />
            <Route path="/faq" element={<HomeFAQ />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/contact" element={<Contact />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
